$body-color-overrided: #333333;
$heading-color-overrided: #00525B;
$c-primary: #00354b;
$c-secandary: #049BA7;
$base-font-family: 'Avenir LT Std', Arial, sans-serif;
$font-family-heading: 'VAG Rundschrift D', Arial, sans-serif;

      @font-face {
        font-family: 'VAG Rundschrift D';
        src: url('/assets-mobile/fonts/vag_rundschrift_d.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Avenir LT Std';
        src: url('/assets-mobile/fonts/Avenir LT Std 45 Book.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;



  .description {
    text-align: left;

    .langRow {
      border-bottom: 1px solid #ccc;
      padding: 1rem 0.3125rem;
    }

    .img-flag {
      width: 2rem; //32
      height: 1.125rem; //18
    }
  }
}
