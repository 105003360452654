$body-color-overrided: #333333;
$heading-color-overrided: #00525B;
$c-primary: #00354b;
$c-secandary: #049BA7;
$base-font-family: 'Avenir LT Std', Arial, sans-serif;
$font-family-heading: 'VAG Rundschrift D', Arial, sans-serif;

      @font-face {
        font-family: 'VAG Rundschrift D';
        src: url('/assets-mobile/fonts/vag_rundschrift_d.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Avenir LT Std';
        src: url('/assets-mobile/fonts/Avenir LT Std 45 Book.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;

  .buttons {
    display: flex;
    justify-content: space-between;
    height: 100%;

    button {
      width: auto;
      height: 100%;
      min-width: 57%;
      white-space: break-spaces;
      line-height: 1;

      &:first-child {
        text-align: left;
        min-width: min-content !important;
      }

      + button {
        margin-left: .5rem;
      }
    }
  }

  .btnFull {
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      height: 100%;
    }
  }
}
