$body-color-overrided: #333333;
$heading-color-overrided: #00525B;
$c-primary: #00354b;
$c-secandary: #049BA7;
$base-font-family: 'Avenir LT Std', Arial, sans-serif;
$font-family-heading: 'VAG Rundschrift D', Arial, sans-serif;

      @font-face {
        font-family: 'VAG Rundschrift D';
        src: url('/assets-mobile/fonts/vag_rundschrift_d.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Avenir LT Std';
        src: url('/assets-mobile/fonts/Avenir LT Std 45 Book.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.mobile {
  @media only screen and (max-width: 320px) {
    font-size: 14px !important;
  }

  @media only screen and (min-width: 321px) {
    font-size: 16px !important;
  }

  @media only screen and (min-width: 480px) {
    font-size: 20px !important;
  }

  @media only screen and (min-width: 720px) {
    font-size: 24px !important;
  }

  @media only screen and (min-width: 960px) {
    font-size: 28px !important;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 32px !important;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 36px !important;
  }
}

.desktop {
  font-size: 18px !important;
}
