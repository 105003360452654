$body-color-overrided: #333333;
$heading-color-overrided: #00525B;
$c-primary: #00354b;
$c-secandary: #049BA7;
$base-font-family: 'Avenir LT Std', Arial, sans-serif;
$font-family-heading: 'VAG Rundschrift D', Arial, sans-serif;

      @font-face {
        font-family: 'VAG Rundschrift D';
        src: url('/assets-mobile/fonts/vag_rundschrift_d.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Avenir LT Std';
        src: url('/assets-mobile/fonts/Avenir LT Std 45 Book.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$flow-v2-bg: white;
@if variable-exists(flow-v2-bg-header-overrided) {
  $flow-v2-bg: $flow-v2-bg-header-overrided;
}
.warpper {
  display: flex;
  justify-content: stretch;
  z-index: 33;
  background: $flow-v2-bg;
  height: var(--flow-v2-header-size) !important;
  padding: 0.75rem 1.5rem; //24

  .img-container {
    display: flex;
    flex: 1;
    max-width: 75%;
    height: 100%;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .img-container-one-doc {
    display: flex;
    flex: 1;
    margin-top: 1rem;
    margin-left: -0.3rem;
    max-width: 75%;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .right-items {
    display: flex;
    height: 100%;
    margin-left: 1.5rem; //24
    align-items: center;
    font-size: 1rem; //16
    cursor: pointer;

    img {
      height: 1.125rem; //18
    }
  }

  .language-chooser,
  .feedback-link {
    padding: 10px 0;
  }

  .feedback-link {
    color: var(--primary) !important;
    text-decoration: underline;
  }

  &.isDark {
    background: #000 !important;
    z-index: 0;
  }
}

@media (max-width: 760px) {
  .warpper {
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
