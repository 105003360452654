$body-color-overrided: #333333;
$heading-color-overrided: #00525B;
$c-primary: #00354b;
$c-secandary: #049BA7;
$base-font-family: 'Avenir LT Std', Arial, sans-serif;
$font-family-heading: 'VAG Rundschrift D', Arial, sans-serif;

      @font-face {
        font-family: 'VAG Rundschrift D';
        src: url('/assets-mobile/fonts/vag_rundschrift_d.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Avenir LT Std';
        src: url('/assets-mobile/fonts/Avenir LT Std 45 Book.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formInputRow {
  position: relative;

  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 35%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}

.control {
  background-color: white;
  border-bottom: 1px solid rgba(25, 25, 25, 0.32);
  border-radius: 0;
  padding-left: 35%;
}
