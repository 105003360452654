$body-color-overrided: #333333;
$heading-color-overrided: #00525B;
$c-primary: #00354b;
$c-secandary: #049BA7;
$base-font-family: 'Avenir LT Std', Arial, sans-serif;
$font-family-heading: 'VAG Rundschrift D', Arial, sans-serif;

      @font-face {
        font-family: 'VAG Rundschrift D';
        src: url('/assets-mobile/fonts/vag_rundschrift_d.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Avenir LT Std';
        src: url('/assets-mobile/fonts/Avenir LT Std 45 Book.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.heading {
  font-family: var(--heading-font-family);
  color: var(--heading-text-color);
  margin-bottom: 1.5rem; //24
  text-align: left;
  font-weight: 600;
  font-size: var(--heading-font-size);
  line-height: 1.1;
  letter-spacing: -0.03125rem; //-0.5px
}
